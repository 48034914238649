const quiSommesNousDropdown = {
    type: 'dropdown',
    name: 'QUI SOMMES-NOUS',
    items: [{
        name: 'NOTRE VISION',
        path: '/vision'
    }, {
        name: 'QUALITÉ DE SERVICE',
        path: '/qualite'
    },
        {
        name: 'RÉACTIVITÉ',
        path: '/reactivite'
    }, {
        name: 'NOTRE TECHNOLOGIE',
        path: '/technologie'
        }
    ]
};

const genericNavItems =  [{
    type: 'dropdown',
    name: 'NOS SERVICES',
    items: [{
        name: 'TRANSPORT PAR VALET',
        path: '/transport-avec-chauffeur'
    }, {
        name: 'TRANSPORT PAR CAMION',
        path: '/transport-voiture-camion'
    }, {
        name: 'EXPERTISE',
        path: '/expertise'
    }, {
        name: 'RÉPARATION',
        path: '/reparation'
    }, {
        name: 'RESTITUTION',
        path: '/restitution'
    }
    ]
},quiSommesNousDropdown,
];

const maifNavItems =  [{
    type: 'link',
    name: 'COMMENT CA MARCHE ?',
    path: '/comment-ca-marche'
},quiSommesNousDropdown,
];

const genericHeader = {
    navItems: genericNavItems,
    ctaButtons: [{
        color: 'bluenavy',
        name: 'ACCÈS CLIENT',
        internalPath: '/professionnels',
    }, {
        color: 'yellow',
        name: 'DEVENIR VALET',
        externalPath: 'https://recrutement.popvalet.com/',
    }]
};

const mainProHeader = {
    navItems: genericNavItems,
    ctaButtons: [{
        color: 'bluenavy',
        name: 'OUVRIR UN COMPTE',
        pageAnchor: '#devenez-client',
    }]
};

const proHeader = {
    navItems: genericNavItems,
    ctaButtons: [{
        color: 'bluenavy',
        name: 'OUVRIR UN COMPTE',
        internalPath: '/professionnels#devenez-client',
    }]
};

const particulierHeader = {
    navItems: [quiSommesNousDropdown],
    ctaButtons: [{
        color: 'violetclair',
        name: 'RÉSERVER MON TRANSPORT',
        pageAnchor: '#reserver-transport'
    }]
};

const maifHeader = {
    navItems: maifNavItems,
    ctaButtons: [{
        color: 'yellow',
        name: 'RÉSERVER',
        internalPath: '/reserver-votre-transport'
    }]
};

export const trexteServices1Data = {
    transportCamion: "TRANSPORT CAMION",
    dpanneusePlateauPorte5Ou8: "Dépanneuse, plateau, porte 5 ou 8",
};

export const trexteServices2Data = {
    transportCamion: "RÉPARATION",
    dpanneusePlateauPorte5Ou8: "Mécanique, carosserie, reconditionnement",
};

export const trexteServices3Data = {
    transportCamion: "EXPERTISE",
    dpanneusePlateauPorte5Ou8: "Sur site, à distance ou combinée",
};

export const trexteServices4Data = {
    transportCamion: "RESTITUTION",
    dpanneusePlateauPorte5Ou8: "Sur parc ou centre automobile",
};

export const ctaOuvrirCompte2Data = {
    className: "cta_ouvrir_compte-1",
};

export const group71Data = {
    treRecontact: "ÊTRE RECONTACTÉ",
};

export const pictoReactivite1Data = {
    className: "",
};

export const voirTousLesUsesCases21Data = {
    children: "En savoir plus >",
    link: "/reactivite"
};

export const reactivit1Data = {
    pictoReactiviteProps: pictoReactivite1Data,
    voirTousLesUsesCases2Props: voirTousLesUsesCases21Data,
};

export const voirTousLesUsesCases22Data = {
    children: "En savoir plus >",
    link: "/qualite"
};

export const qualit1Data = {
    voirTousLesUsesCases2Props: voirTousLesUsesCases22Data,
};

export const blocAvantages1Data = {
    reactivitProps: reactivit1Data,
    qualitProps: qualit1Data,
};

export const rserverUnTransport221Data = {
    children: "Notre technologie >",
    link: "/technologie"
};

export const group1112Data = {
    className: "group-111",
};

export const group1113Data = {
    className: "group-4-1",
};

export const group1161Data = {
    confirmationAutomatique: "Confirmation automatique",
    group111Props: group1113Data,
};

export const group1114Data = {
    className: "group-115-1",
};

export const group1115Data = {
    className: "group-4",
};

export const group1162Data = {
    confirmationAutomatique: "Affectation automatique",
    className: "group-120",
    group111Props: group1115Data,
};

export const group1116Data = {
    className: "group-111-1",
};

export const group1241Data = {
    spanText1: "2 500 valets professionnels formés disponibles",
    spanText2: "et plus de 150 véhicules divers : dépanneuses, plateaux, camions, porte-véhicules",
};

export const x2500Valets1Data = {
    group124Props: group1241Data,
};

export const group1242Data = {
    spanText1: "Plus de 700 experts certifiés",
    spanText2: "disponibles sur site ou à distance (EAD) selon votre besoin et possibilité d’intelligence artificielle",
};

export const group1243Data = {
    spanText1: "20 parcs de stockage et/ou de restitution",
    spanText2: "pour optimiser votre transport, vos coûts et les interventions nécessaires sur votre véhicule",
};

export const x2500Valets2Data = {
    className: "bloc_reseau_popvalet-item",
    group124Props: group1243Data,
};

export const group1244Data = {
    spanText1: "15 garages automobiles (mécaniques/carrosserie)",
    spanText2: "habilités pour effectuer tous vos travaux de réparation avec préparation esthétique",
};

export const x2500Valets3Data = {
    className: "bloc_reseau_popvalet-item",
    group124Props: group1244Data,
};

export const ctaDevenirValetData = {
    accsValet: "DEVENIR VALET",
};

export const accueilPopvaletProData = {
    header: mainProHeader,
    textAssurance: "En tant que professionnel de l’automobile, vous êtes couverts de A à Z avec l’assurance spécifique Pop Valet",
    spanText1: "NOS TÉMOIGNAGES",
    spanText2: "Ce qu’ils disent de nous...",
    text2: "“",
    uneSuperQualitDe: "Une super qualité de service et des missions réalisées avec professionnalisme.",
    spanText3: "BÉNÉFICIEZ DE LA TECHNOLOGIE LA PLUS COMPLÈTE",
    spanText4: "Gagnez en productivité",
    saisieEnQuelquesClics: "Saisie en quelques clics",
    tatDesLieuxDigitaliss: "État des lieux digitalisés",
    disponibilitEnTempsRel: "Disponibilité en temps réel",
    suiviEnTempsRel: "Suivi en temps réel",
    recueilDesAvis: "Recueil des avis",
    envoiAutomatiqueDesDocuments: "Envoi automatique des documents",
    apiDocumente: "API documentée",
    spanText5: "LE RÉSEAU POP VALET",
    spanText6: "Une large palette de ressources",
    enTantQueProfessi: "En tant que professionnel de l’automobile, vous êtes couverts de A à Z avec l’assurance spécifique Pop Valet",
    spanText7: "Vous souhaitez connaître le fonctionnement et les avantages de devenir Valet ?",
    spanText8: "Découvrez notre page dédiée....",
    trexteServices1Props: trexteServices1Data,
    trexteServices2Props: trexteServices2Data,
    trexteServices3Props: trexteServices3Data,
    trexteServices4Props: trexteServices4Data,
    ctaOuvrirCompteProps: ctaOuvrirCompte2Data,
    group71Props: group71Data,
    blocAvantagesProps: blocAvantages1Data,
    rserverUnTransport22Props: rserverUnTransport221Data,
    group1112Props: group1112Data,
    group1161Props: group1161Data,
    group1113Props: group1114Data,
    group1162Props: group1162Data,
    group1114Props: group1116Data,
    x2500Valets1Props: x2500Valets1Data,
    group124Props: group1242Data,
    x2500Valets2Props: x2500Valets2Data,
    x2500Valets3Props: x2500Valets3Data,
    ctaDevenirValetProps: ctaDevenirValetData,
};

export const popvaletGeneralAccueilData = {
    header: genericHeader,
    textAssurance: "Que vous soyez Valet, professionnel ou particulier, vous êtes assurés pour tous vos besoins"
};

export const pictoReactivite2Data = {
    className: "picto_reactivite-1",
};

export const voirTousLesUsesCases24Data = {
    children: "En savoir plus >",
    link: "/reactivite"

};

export const reactivit2Data = {
    pictoReactiviteProps: pictoReactivite2Data,
    voirTousLesUsesCases2Props: voirTousLesUsesCases24Data,
};

export const voirTousLesUsesCases25Data = {
    children: "En savoir plus >",
    link: "/qualite"
};

export const qualit2Data = {
    voirTousLesUsesCases2Props: voirTousLesUsesCases25Data,
};

export const blocAvantages2Data = {
    className: "bloc_avantages-1",
    reactivitProps: reactivit2Data,
    qualitProps: qualit2Data,
};

export const popvaletParticulierAccueilData = {
    header: particulierHeader,
    blocAvantagesProps: blocAvantages2Data,
    textAssurance: "En tant que particulier ou professionnel de l’automobile, vous êtes couverts de A à Z avec l’assurance spécifique Pop Valet"
};

export const popvaletReserverTransportData = {
    header: particulierHeader,
    idReservation: "063467d8-4378-47fe-b0e9-590f014e37b4"
};

export const popvaletMaifReserverTransportData = {
    header: maifHeader,
    idReservation: "63cc2e16-eb3d-43eb-8c44-2c980abc93d3"
};

export const popvaletMatmutReserverTransportData = {
    header: maifHeader,
    idReservation: "326180ba-eecf-4106-89bd-615b8bfce9b0"
};

export const camions1Data = {
    spanText1: "Création de Parkadom",
    spanText2: "la première plateforme de partage de parkings",
};

export const camions2Data = {
    spanText1: "Lancement de Pop Valet",
    spanText2: "service de voiturier à la demande",
};


export const camions22Data = {
    spanText1: "Pivot de Pop Valet",
    spanText2: "vers le transport automobile à la demande",
};

export const camions3Data = {
    spanText1: "Levée de fonds série A",
    spanText2: "avec la MAIF et Inter-Mutuelles Assistance",
};

export const camions23Data = {
    spanText1: "Lancement de nouveaux services",
    spanText2: "et refonte de notre site internet ",
};

export const pictoReactivite3Data = {
    className: "picto_reactivite-2",
};

export const voirTousLesUsesCases26Data = {
    children: "En savoir plus >",
    link: "/reactivite"
};

export const reactivit3Data = {
    pictoReactiviteProps: pictoReactivite3Data,
    voirTousLesUsesCases2Props: voirTousLesUsesCases26Data,
};

export const voirTousLesUsesCases27Data = {
    children: "En savoir plus >",
    link: "/qualite"
};

export const qualit3Data = {
    voirTousLesUsesCases2Props: voirTousLesUsesCases27Data,
};

export const blocAvantages22Data = {
    lesAvantagesDeLaSolutionPopValet: "LES AVANTAGES DE LA SOLUTION POP VALET",
    reactivitProps: reactivit3Data,
    qualitProps: qualit3Data,
};

export const popvaletGeneralVisionData = {
    header: genericHeader,
    title: "Notre vision",
    nousSommesConvainc: "Faire appel à l’équipe Pop Valet, c’est vous faire accompagner par des spécialistes pour toutes vos thématiques liées à la logistique automobile. Notre vision ? Vous permettre, grâce à nos outils digitaux, de combiner une série de services pour créer votre propre flux logistique sur-mesure et, ainsi, développer vos nouvelles mobilités et vous accompagner dans tous vos besoins.",
    popvaletEnQuelquesDates: "Pop Valet en quelques dates",
    number1: "2012",
    number2: "2015",
    number3: "2016",
    number4: "2019",
    number5: "2023",
    camions1Props: camions1Data,
    camions2Props: camions2Data,
    camions21Props: camions22Data,
    camions3Props: camions3Data,
    camions22Props: camions23Data,
    blocAvantages2Props: blocAvantages22Data,
};

export const voirTousLesUsesCases28Data = {
    children: "En savoir plus >",
    className: "voir-tous-les-uses-cases-7",
};

export const rserverUnTransport3Data = {
    className: "rserver-un-transport-5",
};

export const rserverUnTransport223Data = {
    children: "Notre technologie >",
    className: "rserver-un-transport-1",
};

export const popvaletGeneralQualiteData = {
    header: genericHeader,
    qualitDeService: "Qualité de service",
    spanText1: "92 %",
    spanText2: "de scoring moyen",
    oprateurLogistique: "Opérateur Logistique",
    collecteDesDisponi: "Collecte des disponibilités des valets en amont​<br />Réception des missions confiées par les clients<br />Optimisation grâce notre algorithme<br />Dispatch aux valets les plus performants (scoring)<br />Réalisation de la mission en sous 24h",
    slectionFormation: "Sélection & Formation",
    spanText3: "4 %",
    spanText4: "de candidats retennus",
    spanText5: "Candidatures",
    spanText6: "Offres publiées sur une quinzaine de sites partenaires et recommandations encouragées",
    number1: "1",
    spanText7: "Formation théorique",
    spanText8: "Revue des process et des outils utilisés chez Pop Valet via nos modules de formation sanctionnés par un test",
    number2: "5",
    spanText9: "Tri et sélection",
    spanText10: "Application de critères : 5 ans de permis, 8 points minimum, et expérience de conduite",
    number3: "2",
    spanText11: "Formation pratique",
    spanText12: "Vérification des réflexes de conduite, du respect du code de la Route et du respect des process mis en place",
    number4: "6",
    spanText13: "Entretien vidéo",
    spanText14: "Validation de la présentation du candidat, de son élocution et de son expression orale",
    number5: "3",
    spanText15: "Bilan et décision",
    spanText16: "Validation définitive: suivi quotidien et individualisé des premières missions réalisées avec différents process",
    number6: "7",
    spanText17: "Signature du contrat",
    spanText18: "Acceptation de nos conditions et engagement à respecter la Charte de Qualité Pop Valet",
    number7: "4",
    notreOrganisation: "Notre Organisation",
    contrleQualit: "Contrôle Qualité",
    convaincusQueLaQu: "Convaincus que la Qualité de service fera la différence à long terme sur notre métier, nous y avons dédié un pôle à part entière.<br />Chaque mission réalisée est contrôlée au niveau de sa conformité: respect des process (et donc des créneaux), des documents et des états des lieux.<br />Le taux de conformité est l'une des bases du calcul du scoring de chacun de nos Valets",
    spanText19: "97 %",
    spanText20: "de nos missions sont conformes",
    missionsDuPleQualit: "Missions du Pôle qualité",
    organiserEtContrl: "Organiser et contrôler les formations pratiques et théoriques de nos Valets<br />Evaluer régulièrement nos Valets sur le terrain<br />S'assurer que les procédures demandées par les clients soient respectées<br />Rappeler à l'ordre nos Valets lorsque nécéssaire et prendre des actions<br />S'assurer du respect des documents (lisibilité) et des états des lieux​<br />Travailler à l'obtention des normes, dont ISO 9001",
    voirTousLesUsesCases2Props: voirTousLesUsesCases28Data,
    rserverUnTransportProps: rserverUnTransport3Data,
    rserverUnTransport22Props: rserverUnTransport223Data,
};

export const popvaletProServicesValetData = {
    header: proHeader,
    introText: 'Nous intervenons auprès du secteur automobile à travers la mise en place d’une solution innovante de transport de véhicules à la demande. Notre réactivité est en rupture avec les délais traditionnels visibles sur cette activité. Sous 24h, nous prenons en charge le véhicule et le livrons. Autopartage, loueurs de courte durée ou ateliers, nous avons des accords avec une large typologie de clients. Nous traitons aussi des opérations spéciales qui peuvent impliquer le déplacement de plusieurs dizaines de véhicules en simultané ou sur de courts délais.',
    services: [{
        name: 'LIVRAISON',
        iconUrl: require('../img/livraison.png'),
        popup: {
            title: 'Livraison de véhicules neufs, d’occasion ou de location en France et en Europe',
            content: 'La livraison de véhicules neufs, d’occasion ou de location en France et en Europe constitue l’un des piliers du service de transport par Valet orchestré par Pop Valet. En 24 heures seulement, nos quelque 2 500 chauffeurs assurent la livraison de tous vos véhicules à vos clients, les professionnels comme les particuliers, à l’adresse indiquée grâce à notre solution numérique. Concrètement, comment se passe la livraison de votre véhicule ? À quels types de professionnels s’adresse ce service ? Pourquoi choisir la solution Pop Valet ? Nos équipes vous répondent.',
            link: '/livraison-voiture'
        }
    }, {
        name: 'CONVOYAGE',
        iconUrl: require('../img/convoyage.png'),
        popup: {
            title: 'Convoyage de véhicules par la route en France et en Europe',
            content: 'Le convoyage de véhicules routiers en France et en Europe est l’un des fondements de Pop Valet et de son service de transport par Valet. Toute l’année, nos 2 500 chauffeurs se mobilisent pour transporter vos voitures d’un point A à un point B. Nos atouts : optimisation du trajet, réduction des coûts, et réalisation de toute intervention nécessaire sur votre véhicule ou votre flotte. À qui s’adresse notre service de convoyage ? Qui sont les Valets assurant la liaison entre l’adresse de départ et celle de destination ? Nos équipes vous éclairent.',
            link: '/convoyage-voiture'
        }
    }, {
        name: 'JOCKEYAGE',
        iconUrl: require('../img/jockeyage.png'),
        popup: {
            title: 'Jockeyage de véhicules : transport et entretien dans toute la France',
            content: 'Avec la livraison et le convoyage, le jockeyage de véhicules routiers complète l’offre de transport par Valet mise en place en France et en Europe par nos équipes. Ils sont près de 2 500 chauffeurs Pop Valet à assurer un service de conciergerie irréprochable pour l’entretien de vos voitures neuves ou d’occasion. En quoi consiste exactement notre service de jockeyage automobile ? Quelles sont les différentes interventions assurées dans le cadre de notre service de voituriers ? Quels sont les atouts du jockeyage pensé et orchestré par Pop Valet ? Réponses de nos experts.',
            link: '/jockeyage-voiture'
        }
    }],
    pictureUrl: require('../img/stock-photo-car-service-transportation-concept-tow-truck-transpo.webp'),
    avantages: [
        'Service sur-mesure',
        'Réactivité',
        'Livraison du client à sa porte (y compris en centre-ville)',
        'Ajout de services complémentaires (lavage, remise à niveau de carburant, mise en main...)'
    ]
};

export const ctaOuvrirCompte4Data = {
    className: "cta_ouvrir_compte-3",
};

export const voirTousLesUsesCases212Data = {
    children: "En savoir plus >",
    className: "savoirplus-4",
};

export const camions32Data = {
    spanText1: "RELEVÉ DE DOMMAGES",
    spanText2: "Relevé de dommages à travers 40 photos et un contrôle des commandes",
};

export const camions33Data = {
    spanText1: "TRAJET",
    spanText2: "Suivi des étapes en temps réel et notifications intermédiaires",
    className: "camions-4",
};

export const fonctionnement31Data = {
    number: "3",
    camions3Props: camions33Data,
};

export const camions34Data = {
    spanText1: "RAPPORT",
    spanText2: "Chiffrage précis par un un expert certifié sous 24h",
    className: "camions-4",
};

export const fonctionnement32Data = {
    number: "4",
    className: "fonctionnement4",
    camions3Props: camions34Data,
};

export const popvaletProServicesExpertiseData = {
    header: proHeader,
    introText: 'Vous êtes un professionnel de l’automobile et recherchez une solution d’expertise auto dans le cadre d’un sinistre, d’une reprise ou encore d’une réaffectation ? Sur tout le territoire métropolitain, Pop Valet met à votre service son réseau de spécialistes, avec la possibilité de coupler cette prestation avec le transport du véhicule concerné, pour une économie de temps et d’argent.\n' +
        'Nos équipes vous éclairent sur les champs couverts par l’expertise automobile et vous fournissent des exemples de nos prestations.',
    services: [{
        name: 'EXPERTISE SUR PLACE',
        iconUrl: require('../img/expertise_sur_place.png'),
        popup: {
            title: 'Expertise automobile de vos véhicules professionnels',
            content: 'Vous êtes un professionnel de l’automobile et recherchez une solution d’expertise auto dans le cadre d’un sinistre, d’une reprise ou encore d’une réaffectation ? Sur tout le territoire métropolitain, Pop Valet met à votre service son réseau de spécialistes, avec la possibilité de coupler cette prestation avec le transport du véhicule concerné, pour une économie de temps et d’argent. Nos équipes vous éclairent sur les champs couverts par l’expertise automobile et vous fournissent des exemples de nos prestations.',
            link: '/expertise-vehicules-professionnels'
        }
    }, {
        name: 'EXPERTISE À DISTANCE',
        iconUrl: require('../img/expertise_a_distance.png'),
        popup: {
            title: 'Expertise automobile de vos véhicules professionnels',
            content: 'Vous êtes un professionnel de l’automobile et recherchez une solution d’expertise auto dans le cadre d’un sinistre, d’une reprise ou encore d’une réaffectation ? Sur tout le territoire métropolitain, Pop Valet met à votre service son réseau de spécialistes, avec la possibilité de coupler cette prestation avec le transport du véhicule concerné, pour une économie de temps et d’argent. Nos équipes vous éclairent sur les champs couverts par l’expertise automobile et vous fournissent des exemples de nos prestations.',
            link: '/expertise-vehicules-professionnels'
        }
    }],
    avantages: [
        'Plus de 700 experts partenaires certifiés',
        '30 % d\'économies sur vos expertises',
        'Gain de temps',
        'Chiffrage précis et fiable par un expert indépendant certifié'
    ],
    useCases: [{
        title: 'Restitution / Pré-restitution',
        text: 'Chiffrage des frais de remise en état que vous soyez loueur ou gestionnaire'
    }, {
        title: 'Réaffectation',
        text: 'Estimation des coûts de réparation avant relivraison à de nouveaux'
    }, {
        title: 'Reprise/ Rachat Cash',
        text: 'Chiffrage d\'un reconditionnement de véhicule avant la revente'
    }, {
        title: 'Sinistre',
        text: 'Evaluation des travaux à réaliser suite à un accident ou un litige'
    }],
    fonctionnementDeLExpertise: "FONCTIONNEMENT DE L'EXPERTISE",
    number1: "1",
    spanText11: "COMMANDE",
    spanText12: "Saisie de la demande dans notre interface professionnelle",
    number2: "2",
    ctaOuvrirCompteProps: ctaOuvrirCompte4Data,
    voirTousLesUsesCases2Props: voirTousLesUsesCases212Data,
    camions3Props: camions32Data,
    fonctionnement31Props: fonctionnement31Data,
    fonctionnement32Props: fonctionnement32Data,
};

export const popvaletProServicesCamionData = {
    header: proHeader,
    introText: 'Nous intervenons auprès du secteur automobile à travers la mise en place d’une solution innovante de transport de véhicules sur camion à la demande. Notre réactivité est en rupture avec les délais traditionnels visibles sur cette activité. Sous 48 à 72h, nous prenons en charge le véhicule sur un camions et le livrons. Véhicules prestigieux, véhicules non roulants, nous avons des accords avec une large typologie de clients. Nous traitons aussi des opérations spéciales qui peuvent impliquer le déplacement de plusieurs dizaines de véhicules en simultané ou sur de courts délais.',
    services: [{
        name: 'DÉPANNEUSE',
        iconUrl: require('../img/camion_depanneuse.png'),
        popup: {
            title: 'Transport en dépanneuse',
            content: 'Les solutions de transport par camion imaginées et mises en place par Pop Valet, ce sont aussi des dépanneuses à votre disposition pour déplacer vos véhicules roulants, non roulants, neufs, d’occasion ou encore de collection. Délais, garanties, lieux de retrait et de livraison : découvrez les possibilités qui s’offrent à vous pour vos convoyages en toute sécurité.',
            link: '/transport-vehicule-depanneuse'
        }
    }, {
        name: 'CAMION PLATEAU',
        iconUrl: require('../img/camion_plateau.png'),
        popup: {
            title: 'Transport en camion plateau (porte 1)',
            content: 'Les solutions de transport par camion imaginées et mises en place par Pop Valet, ce sont aussi des camions plateau à votre disposition pour déplacer vos véhicules roulants, non roulants, neufs, d’occasion ou encore de collection. Délais, garanties, lieux de retrait et de livraison : découvrez les possibilités qui s’offrent à vous pour vos convoyages en toute sécurité.',
            link: '/transport-vehicule-plateau'
        }
    }, {
        name: 'CAMION PORTE 8',
        iconUrl: require('../img/camion_poret8.png'),
        popup: {
            title: 'Transport en camion porte 8 voitures',
            content: 'Les solutions de transport par camion pensées par Pop Valet impliquent des poids-lourds pouvant déplacer jusqu’à huit voitures en même temps. Les atouts d’une telle méthode ? Elle est économique, elle est fiable, et elle s’impose naturellement aux voitures neuves pour lesquelles vous ne souhaitez pas ajouter de kilomètres au compteur, aux véhicules de prestige ou de collection, ou encore aux véhicules électriques. Délais de livraison, assurance des voitures transportées, adresses de chargement et de déchargement : nos équipes vous présentent leur outil porte 8.',
            link: '/transport-vehicule-porte8'
        }
    }],
    pictureUrl: require('../img/photo-car-service-transportation-concept-tow-truck-transpo-1.webp'),
    avantages: [
        'Livraison sans ajout de kilomètres au compteur',
        'Economies sur la longue distance',
        'Livraison de véhicules de collection ou de prestige',
        'Transport simultané de plusieurs véhicules en une fois'
    ]
};

export const popvaletProServicesReparationData = {
    header: proHeader,
    avantages: [
        'Lead time performant',
        'Jusqu\'à 40 % d\'économies sur vos frais de remise en état',
        'Capacité à couvrir tous types de réparations',
        '2 500 Valets professionnels formés pour récupérer vos véhicules'
    ]
}
export const popvaletProServicesRestitutionData = {
    header: proHeader,
}
export const popvaletGeneralAssuranceData = {
    header: genericHeader,
}
export const popvaletGeneralMaifAssuranceData = {
    header: maifHeader,
    isMaif: true,
}
export const popvaletGeneralNosUsesCasesData = {
    header: genericHeader,
}
export const popvaletGeneralMaifReactiviteData = {
    header: maifHeader,
    isMaif: true,
};
export const popvaletGeneralMaifTechnologieData = {
    header: maifHeader,
    isMaif: true,
};
export const popvaletGeneralCGUData = {
    header: genericHeader,
};
export const popvaletGeneralMaifCGUData = {
    header: maifHeader,
    isMaif: true,
};
export const popvaletGeneralContactData = {
    header: genericHeader,
};
export const popvaletGeneralMaifContactData = {
    header: maifHeader,
    isMaif: true,
};

export const accueilMaifData = {
    header: maifHeader,
    assuranceName: 'MAIF'
};

export const accueilMatmutData = {
    header: maifHeader,
    assuranceName: 'Matmut',
    isMatmut: true
};

export const commentCaMarcheMaifData = {
    header: maifHeader,
    titleCard1: 'Suivi en temps réel',
    textCard1: 'Grâce à son application Pop Valet, votre Valet signale les étapes successives de la mission qui lui est confiée. A son départ du garage, vous recevez un lien par SMS vous informant en temps réel de la progression du trajet, et vous pouvez ainsi suivre la position de votre véhicule via la géolocalisation. A tout moment, vous pouvez joindre directement le Valet si nécessaire à travers les coordonnées affichées.',
    iconUrl1: require('../img/locationpin.png'),
    titleCard2: 'États des lieux',
    textCard2: 'Un état des lieux initial est réalisé lors de la récupération du véhicule auprès du garage. Cet état des lieux digitalisé est sécurisé, encrypté, et a une véritable valeur légale. Lorsque le véhicule vous est apporté, un état des lieux final et contradictoire est dressé afin de contrôler d’éventuels dégâts intervenus au cours du transport. Une copie des états des lieux peut vous être fournie sur simple demande auprès du Valet.',
    iconUrl2: require('../img/file-and-folder---approve-file@2x.png'),
    titleCard3: 'Des valets compétents',
    textCard3: 'Le processus de sélection des Valets est très exigeant afin de garantir des prestations de qualité. Au-delà d’attester d’une solide expérience de conduite, nous veillons à collaborer avec des Valets courtois, professionnels et soucieux du respect du Code de la Route. La conformité des missions est contrôlée par notre service Qualité, et vous pouvez noter la livraison réalisée. Votre véhicule est entre de bonnes mains.',
    iconUrl3: require('../img/icon-valet-1@2x.png'),
    assuranceName: 'MAIF',
};

export const commentCaMarcheMatmutData = {
    header: maifHeader,
    titleCard1: 'Suivi en temps réel',
    textCard1: 'Grâce à son application Pop Valet, votre Valet signale les étapes successives de la mission qui lui est confiée. A son départ du garage, vous recevez un lien par SMS vous informant en temps réel de la progression du trajet, et vous pouvez ainsi suivre la position de votre véhicule via la géolocalisation. A tout moment, vous pouvez joindre directement le Valet si nécessaire à travers les coordonnées affichées.',
    iconUrl1: require('../img/locationpin.png'),
    titleCard2: 'États des lieux',
    textCard2: 'Un état des lieux initial est réalisé lors de la récupération du véhicule auprès du garage. Cet état des lieux digitalisé est sécurisé, encrypté, et a une véritable valeur légale. Lorsque le véhicule vous est apporté, un état des lieux final et contradictoire est dressé afin de contrôler d’éventuels dégâts intervenus au cours du transport. Une copie des états des lieux peut vous être fournie sur simple demande auprès du Valet.',
    iconUrl2: require('../img/file-and-folder---approve-file@2x.png'),
    titleCard3: 'Des valets compétents',
    textCard3: 'Le processus de sélection des Valets est très exigeant afin de garantir des prestations de qualité. Au-delà d’attester d’une solide expérience de conduite, nous veillons à collaborer avec des Valets courtois, professionnels et soucieux du respect du Code de la Route. La conformité des missions est contrôlée par notre service Qualité, et vous pouvez noter la livraison réalisée. Votre véhicule est entre de bonnes mains.',
    iconUrl3: require('../img/icon-valet-1@2x.png'),
    assuranceName: 'Matmut',
};

export const privacyPolicyMaifData = {
    header: maifHeader,
    isMaif: true,
    assuranceUrl: 'https://maif.popvalet.com/',
};

export const privacyPolicyMatmutData = {
    header: maifHeader,
    isMaif: true,
    assuranceUrl: 'https://matmut.popvalet.com/',
};

export const popvaletProCommonData = {
    header: proHeader,
};
